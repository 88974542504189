import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().UseCases])} />);

// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './restoration.module.scss';
// import Footer from "../../components/shared/Footer/Footer";
// import {graphql, useStaticQuery} from 'gatsby';
// import {getImagesFluid, viewports} from '../../components/componentsUtils';
// import useBreakpoint from "../../components/hooks/useBreakpoint";
// import CoveredVideo from "../../components/shared/CoveredVideo/CoveredVideo";
// import SlideTypeF from "../../components/shared/ImageSlider/SlideTypeF";
// import Movie from "../../movies/Inspirations/AgedPhoto/inspirations-photos-restoration.mp4";
// import RestorationIconBlack from "../../images/icons/restoration_b.svg";
// import GoToButton from "../../components/shared/GoToButton/GoToButton";
// import {getId, getLink, getRoutes} from "../../components/shared/routes";
  

// const query = graphql` 
// {
//   fluid: allFile(filter: {sourceInstanceName: {eq: "images_AgedPhoto"}}) {
//     nodes {
//       childImageSharp {
//         fluid(maxWidth: 1090, quality: 70) {
//           ...GatsbyImageSharpFluid,
//           originalName
//         }
//       }
//     }
//   }
// }`;


// const AgedPhotoPage = () => {
//   const { t } = useTranslation();
//   const imagesFluid = getImagesFluid(useStaticQuery(query));
//   const pageTitle = `${getRoutes().AgedPhoto.pageTitle} | ${getRoutes().Inspirations.pageTitle}`;

  
//   const breakpoint = useBreakpoint();

//   const getSlide2ImageWrapperStyles = () => {
//     switch (breakpoint.viewport) {
//       case viewports.tabletViewport:
//         return {
//           width: 'calc(100% - 10px)',
//           maxWidth: '500px',
//           marginLeft: 'auto',
//           marginRight: 'auto',
//           marginTop: '0',
//           marginBottom: '0'
//         };
//       default:
//         return {
//           width: '50%',
//         };
//     }
//   };

//   const getSlide3ImageWrapperStyles = () => {
//     switch (breakpoint.viewport) {
//       case viewports.tabletViewport:
//         return {
//           marginLeft: 'auto',
//           marginRight: 'auto',
//           marginTop: '0',
//           marginBottom: '0'
//         };
//       default:
//         return {
//           width: '62%',
//           marginLeft: '0px'
//         };
//     }
//   };

//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Reconstructing poor quality images'}/>

//       <div className={css.wrapper}>

//         <div className={css.Restoration}>
//         <div className={css.wrapper}>
//             <div className={css.slide1}>
//                 <CoveredVideo
//                     videoSrcURL={Movie}
//                     placeholderImage={'Restoration_1_placeholder.jpg'}
//                     imagesFluid={imagesFluid}
//                     icon={RestorationIconBlack}
//                     title="Aged Photos"
//                     text={<>The moments of memorable <br/>emotions accompany aged photos.</>}
//                     buttons={(
//                         <GoToButton
//                             sectionUrl={getLink([getRoutes().AgedPhoto, getRoutes().AgedPhoto.sections.getStarted])}
//                             text="Get started!" 
//                         />
//                     )}
//                 />
//             </div>
//             <div className={css.slide2} id={getRoutes().AgedPhoto.sections.getStarted}>
//                 <SlideTypeF
//                     title="Photos look great again"
//                     text1={<p>Low quality blurred photos are reconstructed to clarity.</p>}
//                     text2={<p>Aged photos gaining incredible look.</p>}
//                     fullImage={'Restoration_2.jpg'}
//                     fullImageAlt="aged photos restoration"
//                     phoneImage1={'Restoration_mobile_1.jpg'}
//                     phoneImage1Alt="aged photos restoration"
//                     phoneImage2={'Restoration_mobile_2.jpg'}
//                     phoneImage2Alt="aged photos restoration"
//                     imagesFluid={imagesFluid}
//                     imagePosition={'right'}
//                     fullImageWrapperStyles={getSlide2ImageWrapperStyles()}
//                 />
//             </div>
//             <div className={css.slide3}>
//                 <SlideTypeF
//                     title="Digitalize and enjoy"
//                     text1={<p>Use a smartphone to create a digital format of aged photos.</p>}
//                     text2={<p>Bring the memorable moments back again.</p>}
//                     fullImage={'Restoration_3.jpg'}
//                     fullImageAlt="digitalize aged photos"
//                     phoneImage2={'Restoration_mobile_3.jpg'}
//                     phoneImage2Alt="digitalize aged photos"
//                     phoneImage2Height={220}
//                     imagesFluid={imagesFluid}
//                     fullImageWrapperStyles={getSlide3ImageWrapperStyles()}
//                 />
//             </div>
//             </div>
//         </div>

//         <div className={css.Footer}>
//           <Footer/>
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default AgedPhotoPage;
